import React from "react"
import { Link } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Not Found" bodyClass="page__404 presentation" />
      <Layout>
        <section className="layout__main s__white">
          <div className="nav__spacer"></div>
          <div className="u__container u__spacing u__vspacing">
            <h1>404</h1>
            <span className="h5">
              We're sorry. We couldn't find what you were looking for.
              <br />
              <Link to="/">Go home</Link> or check our <Link to="/reflections">Reflections</Link> or <Link to="/projects">Projects</Link>.
            </span>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage
